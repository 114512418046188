import React from "react"
import { graphql } from "gatsby"

import FourchetteIcon from "../components/svg/fourchette-icon"
import IconComponent from "../components/icon"
import LocalizedLink from "../components/localizedLink"

import Img from "gatsby-image"
import ImgPolyfill from "gatsby-image/withIEPolyfill"

import SEO from "../components/seo"
import Layout from "../components/layout"

import { FadeInUp } from "../components/animations/fadeInUp"

class TeamPage extends React.Component {

  render() {
    const { data, pageContext: { locale } } = this.props
    const content = data.file.childContentJson.team

    return (
      <Layout {...this.props}>
        <div className={"c-content"}>

          <SEO title="Équipe" />

          <section className="u-pd-vt-xl u-fadeInUp">
            <div className="l-container u-pd-hz-l">
              <FadeInUp yOffset={50} delay={100}>
                <h1 className="c-h1 u-green80 u-uppercase u-semibold u-flex u-flex-center-vt u-mg-bottom-l">
                  <FourchetteIcon className="u-mg-right-l" style={{ width: "60px", height: "15px" }}/>
                  {content.title}
                </h1>
              </FadeInUp>
              <FadeInUp yOffset={50} delay={200}>
                {content.desc.map((item, index) => {
                  return (
                    <p className="u-fs-m u-lh-l u-mg-bottom-s" style={{ maxWidth: "960px"}} key={"team-desc--" + index}>{item}</p>
                  )
                })}
              </FadeInUp>
            </div>
          </section>

          <section className="u-pd-bottom-xl">
            <div className="l-container">

              <div className="l-grid u-pd-vt-xxl u-pd-hz-l">
                <div className="">
                  <h2 className="c-h2 u-bold u-mg-bottom-l u-mg-0@main">{content.sponsors.title}</h2>
                </div>
                <div className="u-flex-1 u-pd-hz-l u-pd-vt-m u-hide u-flex@main">
                  <hr/>
                </div>
                <div className="l-col-12 l-col-6@main">
                  {content.sponsors.desc.map((item, index) => {
                    return (
                      <p className="u-fs-m u-lh-l u-mg-bottom-s" style={{ maxWidth: "960px"}} key={"sponsors-desc--" + index}>{item}</p>
                    )
                  })}
                </div>
              </div>

              <div className="l-grid u-mg-bottom-xxl">
                {content.sponsors.sponsors.map((item, index) => {
                  let img = data.sponsorsImages.nodes.find(x => x.name === item.photo) || data.sponsorsImages.nodes.find(x => x.name === 'chef');
                  return (
                    <div className={"l-col-12 l-col-4@main u-pd-hz-m"} key={"sponsors-" + index}>
                      <div className="u-center u-mg-bottom-xl">

                      <Img key={img.childImageSharp.fixed.src}
                          fixed={img.childImageSharp.fixed}
                          imgStyle={{}}
                          className="u-bordered u-mg-bottom-m"/>

                        <div className="u-flex u-flex-center-vt u-flex-center-hz" style={{ height: "40px"}}>
                          {item.linkedIn &&
                            <a href={item.linkedIn} target="_blank" rel="noopener noreferrer" className="u-primary u-block u-pd-hz-s u-mg-bottom-s">
                              <IconComponent name="linkedin" />
                            </a>
                          }
                          {item.twitter &&
                            <a href={item.twitter} target="_blank" rel="noopener noreferrer" className="u-primary u-block u-pd-hz-s u-mg-bottom-s">
                              <IconComponent name="twitter" />
                            </a>
                          }
                        </div>

                        <h3 className="c-h3 u-fs-m u-bold u-italic u-mg-bottom-xs">{item.name}</h3>
                        {item.title.map((text, jndex) => {
                            return (
                              <p className="u-fs-s u-semi-bold" key={"sponsors-title-" + jndex}>{text}</p>
                            )
                          })}
                      </div>
                    </div>
                  )
                })}
              </div>

            </div>
          </section>

          <section className="u-border-top u-bg-white u-pd-vt-xl">
            <div className="l-container">

              <div className="l-grid u-pd-vt-xxl u-pd-hz-l">
                <div className="">
                  <h2 className="c-h2 u-bold u-mg-bottom-l u-mg-0@main" dangerouslySetInnerHTML={{__html: content.managers.title}}></h2>
                </div>
                <div className="u-flex-1 u-pd-hz-l u-pd-vt-m u-hide u-flex@main">
                  <hr/>
                </div>
                <div className="l-col-12 l-col-6@main">
                  {content.managers.desc.map((item, index) => {
                    return (
                      <p className="u-fs-m u-lh-l u-mg-bottom-s" style={{ maxWidth: "960px"}} key={"managers-desc--" + index}>{item}</p>
                    )
                  })}
                </div>
              </div>

              <div className="l-grid u-mg-bottom-xxl">
                {content.managers.managers.map((item, index) => {
                  let img = data.managersImages.nodes.find(x => x.name === item.photo);
                  return (
                    <div className={"l-col-12 l-col-4@main u-pd-hz-m"} key={"managers-" + index}>
                      <div className="u-center u-mg-bottom-xl">

                        <Img key={img.childImageSharp.fixed.src}
                            fixed={img.childImageSharp.fixed}
                            imgStyle={{}}
                            className="u-bordered u-mg-bottom-m"/>

                        <div className="u-flex u-flex-center-vt u-flex-center-hz">
                          {item.linkedIn &&
                            <a href={item.linkedIn} target="_blank" rel="noopener noreferrer" className="u-primary u-block u-pd-hz-s u-mg-bottom-s">
                              <IconComponent name="linkedin" />
                            </a>
                          }
                          {item.twitter &&
                            <a href={item.twitter} target="_blank" rel="noopener noreferrer" className="u-primary u-block u-pd-hz-s u-mg-bottom-s">
                              <IconComponent name="twitter" />
                            </a>
                          }
                        </div>

                        <h3 className="c-h3 u-fs-m u-bold u-italic u-mg-bottom-xs">{item.name}</h3>
                        {item.title.map((text, jndex) => {
                            return (
                              <p className="u-fs-s u-semi-bold" key={"managers-title-" + jndex}>{text}</p>
                            )
                          })}
                      </div>
                    </div>
                  )
                })}
              </div>

            </div>
          </section>

          <section className="u-border-top u-pd-vt-xl">
            <div className="l-container">

              <div className="l-grid u-pd-vt-xxl u-pd-hz-l">
                <div className="">
                  <h2 className="c-h2 u-bold u-mg-bottom-l u-mg-0@main">{content.partners.title}</h2>
                </div>
                <div className="u-flex-1 u-pd-hz-l u-pd-vt-m u-hide u-flex@main">
                  <hr/>
                </div>
                <div className="l-col-12 l-col-6@main">
                  {content.partners.desc.map((item, index) => {
                    return (
                      <p className="u-fs-m u-lh-l u-mg-bottom-s" style={{ maxWidth: "960px"}} key={"partners-desc--" + index}>{item}</p>
                    )
                  })}
                </div>
              </div>

              <div className="l-grid u-pd-hz-l">
                <div className="u-mg-hz-auto u-pd-bottom-m">
                <h3 className="c-h3 u-bold u-mg-bottom-l u-mg-0@main">{content.business_partners.title}</h3>
                </div>
              </div>

              <div className="l-grid u-mg-bottom-m">
                {content.business_partners.partners.map((item, index) => {
                  let logo = data.teamPartnersImages.nodes.find(x => x.name === item.logo);
                  return (
                    <div className={"l-col-12 l-col-6@main u-pd-hz-m u-mg-bottom-xl"} key={"partners-" + index}>
                      <div className="u-pd-hz-l u-pd-hz-xl@main u-mg-bottom-xl">

                        <div className="u-flex u-flex-center-vt u-flex-center-hz u-mg-bottom-l">
                          <a href={item.link} target="_blank" rel="noopener noreferrer" className="u-block u-width-full u-center">
                            <ImgPolyfill
                                key={logo.name}
                                fixed={logo.childImageSharp.fixed}
                                objectFit="contain"
                                objectPosition="50% 50%"
                                style={{ width: '100%', maxWidth: '300px' }}
                                alt=""
                            />
                          </a>
                        </div>

                        <p className="u-left">{item.desc}</p>

                      </div>
                    </div>
                  )
                })}
              </div>

              <div className="l-grid u-pd-hz-l">
                <div className="u-mg-hz-auto u-pd-bottom-m">
                  <h3 className="c-h3 u-bold u-mg-bottom-l u-mg-0@main">{content.ecosystem_partners.title}</h3>
                </div>
              </div>

              <div className="l-grid u-mg-bottom-m">
                {content.ecosystem_partners.partners.map((item, index) => {
                  let logo = data.teamPartnersImages.nodes.find(x => x.name === item.logo);
                  return (
                    <div className={"l-col-12 l-col-6@main u-pd-hz-m u-mg-bottom-xl"} key={"partners-" + index}>
                      <div className="u-pd-hz-l u-pd-hz-xl@main u-mg-bottom-xl">

                        <div className="u-flex u-flex-center-vt u-flex-center-hz u-mg-bottom-l">
                          <a href={item.link} target="_blank" rel="noopener noreferrer" className="u-block u-width-full u-center">
                            <ImgPolyfill
                                key={logo.name}
                                fixed={logo.childImageSharp.fixed}
                                objectFit="contain"
                                objectPosition="50% 50%"
                                style={{ width: '100%', maxWidth: '300px' }}
                                alt=""
                            />
                          </a>
                        </div>

                        <p className="u-left">{item.desc}</p>

                      </div>
                    </div>
                  )
                })}
              </div>

            </div>
          </section>

          <section className="u-border-top">
            <div className="l-container">
              <div className="l-grid u-pd-vt-xxl u-pd-hz-l u-flex-center-vt">
                <div className="l-col-12 l-col-6@main">
                  <div className="u-fs-m u-bold u-center u-right@main u-mg-bottom-l u-mg-0@main">{content.band.punchline}</div>
                </div>
                <div className="l-col-12 l-col-2@main u-pd-hz-l u-hide u-flex@main">
                  <hr/>
                </div>
                <div className="l-col-12 l-col-4@main u-center u-left@main">
                  <a href={data.file.childContentJson.global.subscriptionLink} target="_blank" rel="noopener noreferrer" className="c-btn c-btn--primary">
                    <span>{content.band.cta}</span>
                    <span>{content.band.cta_hover}</span>
                  </a>
                </div>
              </div>
            </div>
          </section>

        </div>
      </Layout>
    )
  }
}

export default TeamPage

export const pageQuery = graphql`
  query ($locale: String) {
    teamPartnersImages: allFile(filter: {relativeDirectory: {eq: "img/team-partners"}}) {
      nodes {
        name
        childImageSharp {
          fixed(height: 100, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    managersImages: allFile(filter: {relativeDirectory: {eq: "img/managers"}}) {
      nodes {
        name
        childImageSharp {
          fixed(width: 200, height: 200, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    sponsorsImages: allFile(filter: {relativeDirectory: {eq: "img/sponsors"}}) {
      nodes {
        name
        childImageSharp {
          fixed(width: 200, height: 200, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    imgTest: file(relativeDirectory: {eq: "img"}) {
      childImageSharp {
        fixed(width: 200, height: 200 quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    file(relativeDirectory: { eq: $locale }) {
      childContentJson {
        team {
          band {
            cta
            cta_hover
            punchline
          }
          desc
          managers {
            desc
            managers {
              linkedIn
              name
              photo
              title
              twitter
              title
            }
            title
          }
          partners {
            desc
            title
          }
          business_partners {
            partners {
              desc
              link
              logo
            }
            title
          }
          ecosystem_partners {
            partners {
              desc
              link
              logo
            }
            title
          }
          sponsors {
            desc
            sponsors {
              linkedIn
              name
              photo
              title
              twitter
            }
            title
          }
          title
        }
        global {
          subscriptionLink
          header {
            candidate
            lang {
              en
              fr
            }
            navigation {
              accelerator
              english
              blog
              team
              program
              thematic
            }
          }
          footer {
            by
            cgu
            contact
            email
            press
            social {
              facebook
              instagram
              title
              twitter
              linkedin
            }
          }
          partners {
            name
            image
            link
          }
        }
      }
    },
    partnersLogo: allFile(
      filter: { absolutePath: { regex: "//partners//" } }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
